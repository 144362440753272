.image-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.image-logo {
  height: 100px;
}
