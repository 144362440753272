.App-menu {
  background-color: #fff;
  width: 100px;
  z-index: 997;
  top: 60px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  height: 100%;
  font-size: 9pt;
  color: #555;

  position: fixed;
  transition: 0.5s left;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  overflow-y: auto;
  height: calc(100% - 60px);

  display: flex;
  flex-direction: row;

  z-index: 100;
  transition: .4s margin;
}

.App-menu-hide {
  margin-left: -50px;
  transition: .4s margin;
}

.App-menu-list {
  width: 100px;
  display: block;
}

.App-menu-list-hide {
  width: 100px;
  display: block;
}

.App-menu-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.App-menu-item:hover {
  color: #e84e1c;
}

.App-menu-item.active {
  color: #fff;
  background-color: #e84e1c;
}

.App-menu-item.hide {
  align-items: flex-end;
  padding-right: 15px;
}

.App-submenu-list {
  margin-left: -240px;
  background-color: #fff;
  width: 250px;
  z-index: 997;
  top: 60px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  height: 100%;
  font-size: 9pt;
  color: #555;
  position: fixed;
  z-index: 99;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  height: calc(100% - 60px);
  padding: 30px 20px;

  transition: .3s margin;
}

.App-submenu-list.show{
  margin-left: 100px;
  transition: .3s margin;
}

.App-submenu-list.show.hide-main{
  margin-left: 50px;
  transition: .3s margin;
}

.App-submenu-list .title{
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 11pt;
}

.App-submenu-list .item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 10pt;
  cursor: pointer;
}

.App-submenu-list .item .sub-item-w-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 3px;
  font-size: 9.5pt;
  margin-top: 2px;
}

.App-submenu-list .item .sub-item-w-icon .icon {
  width: 20px;
  /* margin-right: 5px; */
}

.App-submenu-list .item .sub-item-w-icon .text {
  width: 100%;
  margin-left: 5px;
  line-height: 13pt;
  padding-bottom: 3px;
}

.App-submenu-list .item .sub-item-w-icon:hover {
  background-color: antiquewhite;
}

.App-submenu-list .sub-item{
  padding: 4px;
  margin-left: 16px;
}

.App-submenu-list .item:hover{
  color: #e84e1c;
}
