.wd-body {
  padding: 0px 0px 10px 0px;
  min-height: calc(100vh - 60px * 2);
}

.wd-left {
  /* border: 1px solid #ff9988; */
  min-width: 17%;
  width: 17%;
  min-height: 500px;
  padding: 4px 10px;
}

.wd-content {
  /*border: 1px solid #ff9988; */
  width: 100%;
  padding: 4px 0px;
}

.wd-content-full {
  width: 100%;
}

.wd-content-no-right {
  width: 83%;
}

.wd-right {
  /*border: 1px solid #ff9988; */
  width: 20%;
  min-height: 500px;
  padding: 4px 10px;
}

.wd-panel-box {
  width: 100%;
  min-height: 60px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 5px 6px 0px rgba(175, 175, 175, 0.2);
  padding: 15px 20px 30px 20px;
  font-size: 12pt;
}

.wd-panel-box .title {
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  text-transform: capitalize;
}

.wd-panel-box div {
  animation: shake 0.2s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.wd-panel-box .item {
  padding-top: 12px;
  text-transform: capitalize;
  cursor: pointer;
  color: #666;
}

.wd-panel-box .item-selected div{
  color: var(--themeColor);
  font-weight: 600;
}

.wd-panel-box .content {
  padding-top: 8px;
  color: #666;
}

.wd-panel-box .item:hover {
  color: var(--themeColor)
}

.wd-panel-box .sub-item {
  padding-top: 8px;
  text-transform: capitalize;
  cursor: pointer;
  color: #888;
  padding-left: 27px;
  font-size: 9pt;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wd-panel-box .sub-item .count {
  padding: 0px 5px;
  background-color: var(--themeColor);
  color: white;
  font-size: 6pt;
  font-weight: bold;
  border-radius: 5px;
  min-width: 15px;
  height: 15px;
  line-height: 15px;
  margin-left: 2px;
}

.wd-panel-box .sub-item-selected div{
  color: var(--themeColor);
  font-weight: 500;
}

.wd-panel-box .sub-item:hover {
  color: var(--themeColor)
}

.wd-panel-box .back {
  cursor: pointer;
}

.wd-panel-box .back:hover {
  color: var(--themeColor)
}

.wd-filter {
  margin-top: 5px;
}

.wd-breadcrumb {
  padding: 20px 0px 10px 0px;
}

.wd-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  /* margin-top: 5px;
  margin-bottom: 10px; */
}

.wd-header .title {
  padding-top: 5px;
}

.wd-header h2 {
  /* margin-top: 8px; */
  font-size: 13pt;
  font-weight: bolder;
  text-transform: uppercase;
  color: rgb(232,78,28);
  /* padding: 1px 2px; */
  /*background: rgb(232,78,28);
  background: linear-gradient(90deg, rgba(232,78,28,1) 0%, rgba(240,104,55,1) 100%);*/
}

.wd-header .tag {
  margin-top: -8px;
  margin-left: 6px;
  font-size: 9pt;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  background-color: #fff;
  border-radius: 10px;
  padding: 1px 10px;
  min-width: 22px;
  height: 22px;
}

.wd-header .header-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: -4px;
}

.wd-header .header-desc {
  color: #555;
  margin-top: 15px;
  text-transform: capitalize;
}

.wd-header.header-compact .header-action {
  margin-top: 0;
}

.wd-section {
  background-color: #fff;
  border: 1px solid #E7EAEC;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 20px;
}

.wd-tab {
  background-color: #f4f8fc;
  border-radius: 6px;
  overflow: visible;
  padding: 20px 20px;
}

.wd-icon {
  color: var(--themeColor)
}

.wd-footer {
  padding: 0px 20px 5px 20px;
  font-size: 8.5pt;
  color: #222;
  width: 100%;
  text-align: center;
}

.wd-footer .app-logo {
  width: 100px;
  height: 33.333px;
  margin: 0px 1px -4px 1px;
  /* background-color: white; */
  border-radius: 6px;
  vertical-align: baseline;
}


@media screen and (max-width: 760px) {
  .wd-body {
    padding: 20px 10px;
  }

  .wd-header {
    padding: 15px 5px;
  }

  .wd-left {
    display: none;
  }

  .wd-content {
    width: 100%;
  }

  .wd-right {
    display: none;
  }
}


@keyframes shake {
  10% {
    transform: translate3d(12px, 0, 0);
  }

  60% {
    transform: translate3d(-5px, 0, 0);
  }

  100% {
    transform: translate3d(0px, 0, 0);
  }
}
