.App {
  min-height: 100vh;
}

.App .logo {
  height: auto;
  width: 30px;
  padding: 20px 0px;
  margin: 20px 10px 20px 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 58.55px;
  font-size: calc(10px + 2vmin);
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 55;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  padding: 0px 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
}

.App-header .left{
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-header .right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}

.App-header .menu{
  width: 100%;
  /* height: 36px; */
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 8px;
}

.App-header .menu:hover{
  background-color: #f4f4f4;
  transition: 0.4s background-color;
}


.App-header .right .avatar{
  height: 30px;
  border-radius: 12px;
  padding: 3px 10px 3px;
}

.App-header .right .avatar .name-white{
  font-size: 11px;
  font-weight: 600;
  color: #f4f4f4;
  white-space: nowrap;
  overflow: hidden;
  min-width: 30px;
  text-align: center;
}

.App-header .right .avatar .name-app{
  font-size: 11px;
  font-weight: 600;
  color: #e84e1c;
  white-space: nowrap;
  overflow: hidden;
  min-width: 30px;
  text-align: center;
}

.App-header .right .avatar .app-org-logo {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.App-toggle {
  cursor: pointer;
  margin-right: 30px;
}

.App-body {
  min-height: calc(100vh);
  /* margin-top: 60px; */
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
}

.App-content {
  margin-left: 100px;
  width: calc(100vw - 100px);
  padding: 60px 30px 0px 30px;
  transition: .4s margin;
  display: block;

  background: #fff9f7;
}

.App-content-no-menu {
  margin-left: 50px;
  width: calc(100vw - 50px);
  transition: .5s margin;
}

.App-link {
  color: #61dafb;
}

.ant-table-thead > tr > th {
  font-weight: 800 !important;
  background-color: #fff !important;
  color: #e84e1c !important;
  border-bottom-color: #e84e1c55 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row-flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
