@switchPrefixCls: rc-switch;

@duration: .3s;

.@{switchPrefixCls} {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 50px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all @duration cubic-bezier(0.35, 0, 0.25, 1);

  &-inner {
    color:#fff;
    font-size: 12px;
    position: absolute;
  }

  &:after{
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: #fff;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left @duration cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: @duration;
    animation-name: rcSwitchOff;
  }

  &:hover:after {
    transform: scale(1.1);
    animation-name: rcSwitchOn;
  }

  &:focus {
    box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }

  &-checked {
    border: 1px solid #87d068;
    background-color: #87d068;

    .@{switchPrefixCls}-inner {
      left: 6px;
    }

    &:after{
      left: 28px;
    }
  }

  &-disabled{
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;

    &:after{
      background: #9e9e9e;
      animation-name: none;
      cursor: no-drop;
    }

    &:hover:after{
      transform: scale(1);
      animation-name: none;
    }
  }

  &-label {
    display: inline-block;
    line-height: 10px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
  }
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
