.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.button-row .btn {
  width: 150px;
}

.action-buttons div {
  margin-right: 15px;
  cursor: pointer;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.billing-rate-set-rows {
  overflow-y: auto;
  height: 600px;
}

.billing-rate-set-row-list {
  margin-bottom: 10px;
}

.billing-rate-set-field {
  font-size: 11px !important;
  text-align: right;
}

.billing-rate-hour {
  position: absolute;
  top: 110%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.billing-set-remove-row {
  cursor: pointer;
  margin: 5px;
  margin-left: 10px;
  margin-right: 15px;
}

.billing-rate-remove-row {
  cursor: pointer;
  margin-right: 5px;
}

.billing-rate-remove-row:hover {
  color: #3fa9ff;
}

.billing-rate-category-name-item {
  font-size: 9.5pt;
  /* font-weight: bold; */
  color: var(--themeColor);
  display: flex;
  flex-direction: row;
}

.billing-rate-info {
  background-color: #fff;
  padding: 0px 10px 0px 10px;
  border-radius: 6px;
}

.billing-rate-info .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.billing-rate-info-separate {
  width: 98%;
  border-top: 1px solid #eee;
  margin: 10px auto;
}

.billing-rate-info-category {
  padding: 15px;
}

.billing-rate-add-category {
  color: var(--themeColor);
  text-align: center;
  cursor: pointer;
}

.billing-rate-add-category:hover {
  color: #888;
}

.billing-rate-row {
  font-size: 12px;
  color: #777;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
}

.billing-rate-row-title {
  font-size: 9.5pt;
  font-weight: bold;
  color: var(--themeColor);
  display: flex;
  flex-direction: row;
}

