.sub-option {
  font-weight: lighter;
  color: #999;
}

.no-login-text {
  font-weight: 300;
  color: #666;
  margin-left: 30px;
}
