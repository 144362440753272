.login {
  background-image: -webkit-linear-gradient(260deg,#fcfcfc 20%, #e84e1c 120%);
  height: 100vh;
  min-height: 100vh;
  overflow-y: hidden;
}

.login .login-box {
  background:#FBFBFA;
  background-image: -webkit-linear-gradient(180degree, #FBFBFA 20%, #ffffff55 100%);
  margin: 0 auto;
  height: 100vh;
  width: calc(100% / 1.1);
  -webkit-box-shadow: -1px 2px 50px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 2px 50px -1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 2px 50px -1px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .login-left-side {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 25%;
}

.login .description {
  margin: -40px 0 30px 0;
  font-weight: bold;
  letter-spacing: 2px;
}

.login .login-pic {
  width: 85%;
  margin: 90px 70px;
  opacity: 0.9;
}

.login .box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 8% 5px 30px;
}

.login .logo {
  height: auto;
  width: 60px;
  padding: 20px 0px;
  margin: 20px 20px 20px 0px;
}

.login .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login .center .title {
  text-align: center;
  font-size: 16px;
  color: var(--themeColorAlt)
}

.login .center .title-err {
  text-align: center;
  color: #f61414;
  font-size: 15px;
  font-weight: 600;
}

.login .row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .title {
  font-size: 18pt;
  color: #E84E1C;
  font-weight: bold;
}

.login .title-sub {
  font-size: 16pt;
  color: var(--themeColorAlt);
  font-weight: bold;
}

.login .container {
  text-align: left;
}

.login .input-text {
  margin-top: 20px;
  text-align: left;
  width: 300px;
}

.login .alert {
  margin-bottom: 12px;
  text-align: left;
}

.login .button {
  width: 80%;
}

.login .copyright {
  padding: 7px 20px;
  font-size: 8.5pt;
  color: #444;
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.login .app-logo {
  width: 100px;
  height: 33.333px;
  margin: 0px 1px -4px 1px;
  /* background-color: white; */
  border-radius: 6px;
  vertical-align: baseline;
}

@media screen and (max-width: 480px) {
  .login .box {
    margin: 0 auto;
    padding: 0 30px;
    border-radius: 15px;
  }

  .login .login-box {
    margin-top: 30px;
    width: calc(100% / 1.15);
  }

  .login .login-left-side {
    display: none;
  }
}

@media screen and (max-width: 400px),
(max-width: 480px) and (max-height: 680px) {
  .login {
    background-color: #fff;
    padding: 0;
  }

  .login .login-box {
    background: none;
    min-width: 240px;
    width: 320px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .login .logo {
    margin: 40px;
    width: 240px;
  }
}

