.Dashboard-summary {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  height: 120px;
  width: 100%;
}

.Dashboard-summary-col {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
  margin: 2px 5px;
  padding: 20px;
}

/* Repsonsive */

@media only screen and (max-width: 720px) {
  .Dashboard-summary {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 50% 50%;
      height: 200px;
      width: 100%;
  }
}
