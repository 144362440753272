.list-text-bold {
  font-size: 10pt;
  font-weight: 700;
  color: #333
}

.list-text-norm {
  font-size: 9pt;
  font-style: italic;
  font-weight: 300;
  color: #666;
  margin-top: -3pt;
}
