.list-text-bold1 {
  font-size: 10pt;
  font-weight: 700;
  color: #333;
}

.list-text-norm1 {
  font-size: 10pt;
  font-style: normal;
  font-weight: 400;
  color: #666;
}

.list-text-italic1 {
  font-size: 9pt;
  font-style: italic;
  font-weight: 400;
  color: #666;
  margin-top: -3pt;
}

.list-text-disabled {
  color: #bbb;
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
