.role-header {
  font-size: 13px;
  font-weight: 600;
  color: #333;
  letter-spacing: 1pt;
  margin-bottom: 8px;
  margin-top: 4px;
}

.row-role {
  align-items: center;
}

.row-role .ant-form-item {
  margin-bottom: 0px !important;
}

.row-role .title {
  font-size: 11px;
  color: #666;
}

.row-role .description {
  font-size: 11px;
  color: #666;
}
